import React, { useEffect } from 'react';
import Head from 'next/head';
import * as Sentry from '@sentry/node';
import { Integrations } from "@sentry/tracing";
import { AppProvider } from '@shopify/polaris';
import { Provider as ShopifyBridgeProvider } from '@shopify/app-bridge-react';
import translations from '@shopify/polaris/locales/en.json';
import TagManager from 'react-gtm-module'
import queryString from 'query-string';
import window from 'global/window'; // Due to nextjs ssr, the window object might not exist
import '@shopify/polaris/dist/styles.css';
import '../styles/globals.css';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== 'development',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

// Required as there is a shopify bug where if the api_key or
// shop origin is undefined, the app gets stuck in an infinite loop.
function BridgeBypass({ children }) {
  return children;
}

function inIframe () {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

function MyApp({ Component, pageProps, err }) {
  useEffect(()=>{
    if(process.env.NODE_ENV !== 'development'){
      TagManager.initialize({ gtmId: 'GTM-N29DGDG' })
    }
  }, []);

  const config = {};
  let BridgeProvider = BridgeBypass;
  const parsed = queryString.parse(window.location && window.location.search)

  // First do an iframe check, then a shopify parameter check
  if (!inIframe() && parsed.shopify_apikey && parsed.shopify_shop_origin) {
    BridgeProvider = ShopifyBridgeProvider;
    config.apiKey = parsed.shopify_apikey;
    config.shopOrigin = parsed.shopify_shop_origin;
  }

  return (
    <React.Fragment>
      <Head>
        <title>ReturnBear</title>
        <link rel='icon' href='/logo.svg' />
        <link rel='preconnect' href='https://fonts.gstatic.com' />
        <link
          href='https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@700&family=IBM+Plex+Sans:wght@400;700&family=Work+Sans:wght@300;500;600;700&display=swap'
          rel='stylesheet' />
      </Head>
      <AppProvider i18n={translations}>
        <BridgeProvider config={config}>
          <Component {...pageProps} err={err} />
        </BridgeProvider>
      </AppProvider>
    </React.Fragment>
  );
}

export default MyApp;
